<template>
  <div class="formBox">
    <div style="display: flex; align-items: center">
      <h4 style="color: #146aff; padding: 0px 20px 0px 30px">活动信息</h4>
    </div>
    <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
      <!-- <el-form-item label="封面图" prop="surfaceDrawing">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="form.surfaceDrawing"
              @uploadOneImgShow="uploadOneImg"
              @removeImg="removeImg"
            ></img-big-upload>
            <span style="color: #cdcdcd"
              >(建议整体尺寸为630*630px，图片格式应为jpg、jpeg、png，图片应小于12MB)
            </span>
          </div>
          <div v-if="form.surfaceDrawing">
            <div>缩略图</div>
            <div class="thumbnail">
              <div class="thumbnail_box">
                <img :src="form.surfaceDrawing" alt="" />
                <div>图片尺寸：229*131 pxx</div>
                <div>图片大小：4MB</div>
                <div>展示位置：首页</div>
              </div>
              <div class="thumbnail_box">
                <img :src="form.surfaceDrawing" alt="" />
                <div>图片尺寸：442*226 px</div>
                <div>图片大小：5MB</div>
                <div>展示位置：文章详情页</div>
              </div>
              <div class="thumbnail_box">
                <img :src="form.surfaceDrawing" alt="" />
                <div>图片尺寸：442*226 px</div>
                <div>图片大小：10MB</div>
                <div>展示位置：首页banner区域</div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item> -->
      <el-form-item label="视频" prop="videoList">
        <div class="cover">
          <div class="img_box" v-if="form.homeVideo">
            <uploadVideo
              :videos="videoUrl"
              @uploadOneImg="uploadOneImgvideo"
              @removeImg="removeImgvideo"
            ></uploadVideo>
            <span style="color: #146aff">建议视频整体尺寸为1920*1080 px，大小不超过20MB</span>
          </div>
          <div class="img_box" v-else>
            <uploadVideo
              @uploadOneImg="uploadOneImgvideo"
              @removeImg="removeImgvideo"
            ></uploadVideo>
            <span style="color: #146aff">建议视频整体尺寸为1920*1080 px，大小不超过20MB</span>
          </div>
          <div v-if="form.coverImage">缩略图</div>
          <div v-if="form.coverImage" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="form.coverImage" alt="" />
              <div>视频尺寸：229*131 px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="form.coverImage" alt="" />
              <div>视频尺寸：229*131 px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：活动详情页</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="封面信息" prop="coverInformationVo">
        <div class="cover_list">
          <div class="cover_item" v-for="(item, index) in form.coverInformationVo" :key="index">
            <div class="item_content">
              <div class="item_title mr-b-10">主办方信息</div>
              <div class="cover mr-b-10">
                <div class="img_box">
                  <img-big-upload
                    :imageShow.sync="item.surfaceDrawing"
                    @uploadOneImgShow="uploadOneImg($event, item)"
                    @removeImg="removeImg($event, item)"
                  ></img-big-upload>
                  <span style="color: #cdcdcd"
                    >(建议整体尺寸为630*630px，图片格式应为jpg、jpeg、png，图片应小于12MB)
                  </span>
                </div>
                <!-- <div v-if="item.surfaceDrawing">
                  <div>缩略图</div>
                  <div class="thumbnail">
                    <div class="thumbnail_box">
                      <img :src="item.surfaceDrawing" alt="" />
                      <div>图片尺寸：229*131 pxx</div>
                      <div>图片大小：4MB</div>
                      <div>展示位置：首页</div>
                    </div>
                    <div class="thumbnail_box">
                      <img :src="item.surfaceDrawing" alt="" />
                      <div>图片尺寸：442*226 px</div>
                      <div>图片大小：5MB</div>
                      <div>展示位置：文章详情页</div>
                    </div>
                    <div class="thumbnail_box">
                      <img :src="item.surfaceDrawing" alt="" />
                      <div>图片尺寸：442*226 px</div>
                      <div>图片大小：10MB</div>
                      <div>展示位置：首页banner区域</div>
                    </div>
                  </div>
                </div> -->
              </div>
              <el-input
                class="mr-b-10"
                v-model="item.activityName"
                placeholder="请输入封面标题"
              ></el-input>
            </div>
            <div class="row">
              <div class="icon_btn" @click="addCoverItemBtn">
                <i class="el-icon-circle-plus add"></i>
              </div>
              <div class="icon_btn" v-if="index > 0" @click="delCoverItemBtn(index)">
                <i class="el-icon-delete del"></i>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="封面标题" prop="activityName">
        <el-input v-model="form.activityName" placeholder="请输入封面标题"></el-input>
      </el-form-item> -->
      <el-form-item label="案例主题" prop="caseSubject">
        <div class="txtree">
          <el-input v-model="form.caseSubject" placeholder="请输入案例主题" />
        </div>
      </el-form-item>
      <el-form-item label="活动类型" prop="activityType">
        <el-radio-group v-model="form.activityType">
          <el-radio label="1">实战课程</el-radio>
          <el-radio label="2">工赋学堂</el-radio>
          <el-radio label="3">创新集市</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动阶段" prop="">
        <el-radio-group v-model="form.activityPhase" @change="setActivityPhase">
          <el-radio label="1">预热中</el-radio>
          <el-radio label="2">规划中</el-radio>
        </el-radio-group>
        <div v-show="form.activityPhase === '1'">
          <div class="mini_input_row">
            <el-input v-model.number="form.phaseNumber" placeholder="请输入数字"></el-input>
            <span>天</span>
          </div>
          <span style="color: #cdcdcd"
            >选择此活动阶段需规约活动开始之前，提前多少天开始进入活动预热的状态
          </span>
        </div>
      </el-form-item>
      <el-form-item label="活动时间" prop="" v-if="form.activityPhase === '2'">
        <el-date-picker
          format="yyyy-MM"
          value-format="yyyy-MM"
          v-model="form.activityProjectTime"
          type="month"
          placeholder="开始日期"
        >
        </el-date-picker>
      </el-form-item>
      <div v-if="form.activityPhase === '1'">
        <el-form-item label="活动时间" prop="">
          <el-date-picker
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            v-model="pickerDateList"
            @change="checkParamsData"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动城市" prop="activityProvince">
          <city-select
            ref="child"
            :province="form.activityProvince"
            :city="form.activityCity"
            :district="form.activityDistrict"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
            @searchDistrict="searchDistrict"
          />
        </el-form-item>
        <el-form-item label="活动地址" prop="activityDetailedAddress">
          <el-input v-model="form.activityDetailedAddress" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="饭店地址" prop="restaurantDetailedAddress">
          <el-input
            v-model="form.restaurantDetailedAddress"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="参与方式" prop="activityType">
          <el-radio-group v-model="form.participationMode">
            <el-radio label="1">线上</el-radio>
            <el-radio label="2">线下</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="活动名额" prop="activityNumber">
          <div class="mini_input_row">
            <el-input v-model="form.activityNumber" placeholder="请输入活动名额"></el-input>
            <span>人</span>
          </div>
        </el-form-item>
        <el-form-item label="活动描述" prop="activityDescription">
          <div class="editBarBox">
            <div class="editBar">
              <Editorbar v-model="form.activityDescription" :isClear="isClear" />
            </div>
          </div>
          <div class="img_tips" style="margin-top: 10px">
            <span
              >(图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
            </span>
          </div>
        </el-form-item>
        <el-form-item label="微信二维码:" prop="">
          <div class="pictures_box_left">
            <img-big-upload
              :imageShow.sync="form.wxImage"
              :addTitleShow="false"
              @removeImg="removeWXImgDians"
              @uploadOneImgShow="uploadOneWXImgShowDians"
            />
          </div>
          <div class="img_tips">
            <span
              >(需添加此活动服务人员的微信二维码，便于建微信群管理报名成功的用户，建议整体尺寸为200*200px，图片格式应为jpg、jpeg、png，图片应小于12MB)
            </span>
          </div>
        </el-form-item>
        <h4 class="h4_share">附加信息</h4>
        <el-form-item label="参与用户" prop="userTypes">
          <el-checkbox-group v-model="form.userTypes">
            <el-checkbox label="0">普通用户</el-checkbox>
            <el-checkbox label="1">秘书长</el-checkbox>
            <el-checkbox label="2">会员用户</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="活动票种:" prop="">
          <div class="tiket_flex" v-for="(item, index) in form.activityTickets" :key="index">
            <div class="ticket_Type">
              <div class="original">
                <el-form-item label="票名" prop="" label-width="40px">
                  <el-input v-model="item.ticketName" placeholder="请输入票名"></el-input>
                </el-form-item>
                <el-form-item label="票数" prop="ticketNumber" label-width="40px">
                  <el-input
                    v-model.number="item.ticketNumber"
                    placeholder="请输入票数"
                    oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
                  ></el-input>
                </el-form-item>
                <el-form-item label="票价" prop="ticketRates" label-width="40px">
                  <el-input v-model.number="item.ticketRates" placeholder="请输入票价"></el-input>
                </el-form-item>
              </div>
              <!-- <div style="position: relative; left: -80px">
                <el-form-item label="是否单人单张" prop="isOne" label-width="120px">
                  <el-radio-group v-model="item.isOne">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div> -->
              <div class="original">
                <el-form-item label="备注" label-width="40px">
                  <el-input v-model="item.remark" placeholder="备注"></el-input>
                </el-form-item>
              </div>
              <div class="add_information">
                <div style="padding: 20px">普通用户</div>
                <div class="online_conference">
                  <el-form-item label="费用类型" prop="">
                    <div>
                      <el-radio-group
                        v-model="item.commonUserExpenseType"
                        @change="discountChanged(item, 1)"
                      >
                        <el-radio label="1">付费</el-radio>
                        <el-radio label="2">免费</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <div v-if="item.commonUserExpenseType == '1'">
                    <el-form-item label="优惠方式" prop="commonUserFavorableType">
                      <div>
                        <el-radio-group
                          v-model="item.commonUserFavorableType"
                          @change="discountChanged(item, 1)"
                        >
                          <el-radio :label="'1'">折扣</el-radio>
                          <el-radio :label="'2'">优惠价</el-radio>
                        </el-radio-group>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-if="item.commonUserFavorableType == '1'"
                      label="折扣"
                      prop="commonUserDiscount"
                    >
                      <div class="original">
                        <el-input-number
                          v-model="item.commonUserDiscount"
                          :min="0"
                          :max="100"
                          label="描述文字"
                        ></el-input-number>
                        <span class="online_color">%</span>
                      </div>
                    </el-form-item>
                    <el-form-item v-else label="优惠价" prop="commonUserDiscount">
                      <div class="original">
                        <el-input
                          v-model="item.commonUserDiscount"
                          placeholder="请输入原价"
                        ></el-input>
                        <span class="online_color">元</span>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="add_information">
                <div style="padding: 20px">秘书长</div>
                <div class="online_conference">
                  <el-form-item label="费用类型" prop="">
                    <div>
                      <el-radio-group
                        v-model="item.secretaryGeneralExpenseType"
                        @change="discountChanged(item, 2)"
                      >
                        <el-radio label="1">付费</el-radio>
                        <el-radio label="2">免费</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <div v-if="item.secretaryGeneralExpenseType == '1'">
                    <el-form-item label="优惠方式" prop="userDiscountType">
                      <div>
                        <el-radio-group
                          v-model="item.secretaryGeneralFavorableType"
                          @change="discountChanged(item, 2)"
                        >
                          <el-radio :label="'1'">折扣</el-radio>
                          <el-radio :label="'2'">优惠价</el-radio>
                        </el-radio-group>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-if="item.secretaryGeneralFavorableType == '1'"
                      label="折扣"
                      prop="discount"
                    >
                      <div class="original">
                        <el-input-number
                          v-model="item.secretaryGeneralDiscount"
                          :min="0"
                          :max="100"
                          label="描述文字"
                        ></el-input-number>
                        <span class="online_color">%</span>
                      </div>
                    </el-form-item>
                    <el-form-item v-else label="优惠价" prop="discount">
                      <div class="original">
                        <el-input
                          v-model="item.secretaryGeneralDiscount"
                          placeholder="请输入原价"
                        ></el-input>
                        <span class="online_color">元</span>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="add_information">
                <div style="padding: 20px">会员用户</div>
                <div class="online_conference">
                  <el-form-item label="费用类型" prop="">
                    <div>
                      <el-radio-group
                        v-model="item.memberExpenseType"
                        @change="discountChanged(item, 3)"
                      >
                        <el-radio label="1">付费</el-radio>
                        <el-radio label="2">免费</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <div v-if="item.memberExpenseType == '1'">
                    <el-form-item label="优惠方式" prop="userDiscountType">
                      <div>
                        <el-radio-group
                          v-model="item.memberFavorableType"
                          @change="discountChanged(item, 3)"
                        >
                          <el-radio label="1">折扣</el-radio>
                          <el-radio label="2">优惠价</el-radio>
                        </el-radio-group>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-if="item.memberFavorableType == '1'"
                      label="折扣"
                      prop="memberDiscount"
                    >
                      <div class="original">
                        <el-input-number
                          v-model="item.memberDiscount"
                          :min="0"
                          :max="100"
                          label="描述文字"
                        ></el-input-number>
                        <span class="online_color">%</span>
                      </div>
                    </el-form-item>
                    <el-form-item v-else label="优惠价" prop="discount">
                      <div class="original">
                        <el-input v-model="item.memberDiscount" placeholder="请输入原价"></el-input>
                        <span class="online_color">元</span>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; font-size: 24px; margin-left: 20px; margin-top: 8px">
              <span v-if="index == 0" style="color: #4e93fb" @click="addItem"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-else style="color: #ff7575" @click="delItem(index)"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="关联文章" prop="">
          <el-autocomplete
            class="flex_input"
            v-model="form.articleName"
            :fetch-suggestions="relateArticleNameQuerySearch"
            placeholder="请输入关键词检索文章"
            :trigger-on-focus="false"
            @select="relateArticleNameSelect"
            @input="relateArticleNameChanged"
          >
          </el-autocomplete>
          <!-- <el-input v-model="form.articleName" placeholder="请输入关键词检索快讯"></el-input> -->
        </el-form-item>
        <el-form-item label="关联商会" prop="">
          <div class="row mr-b-10" v-for="(item, index) in form.chamberCommerceVo" :key="index">
            <el-autocomplete
              class="flex_input"
              v-model="item.chamberCommerceName"
              :fetch-suggestions="relateChamberCommerceNameQuerySearch"
              placeholder="请输入关键词检索商会"
              :trigger-on-focus="false"
              @select="relateChamberCommerceNameSelect($event, item)"
              @input="relateChamberCommerceNameChanged"
            >
            </el-autocomplete>
            <div class="icon_btn" @click="addRelateProjectBtn">
              <i class="el-icon-circle-plus add"></i>
            </div>
            <div class="icon_btn" v-if="index > 0" @click="delRelateProjectBtn(index)">
              <i class="el-icon-delete del"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="关联企业" prop="">
          <div class="row mr-b-10" v-for="(item, index) in form.firmVo" :key="index">
            <el-autocomplete
              class="flex_input"
              v-model="item.companyFullName"
              :fetch-suggestions="relateCompanyFullNameQuerySearch"
              placeholder="请输入关键词检索企业"
              :trigger-on-focus="false"
              @select="relateCompanyFullNameSelect($event, item)"
              @input="relateCompanyFullNameChanged"
            >
            </el-autocomplete>
            <div class="icon_btn" @click="addRelateCompanyFullNameBtn">
              <i class="el-icon-circle-plus add"></i>
            </div>
            <div class="icon_btn" v-if="index > 0" @click="delRelateCompanyFullNameBtn(index)">
              <i class="el-icon-delete del"></i>
            </div>
          </div>
        </el-form-item>
        <!-- <el-form-item label="主办方" prop="sponsor">
        <el-input v-model="form.sponsor" placeholder="请输入主办方"></el-input>
      </el-form-item> -->
        <h4 class="h4_share">小程序分享链接信息</h4>
        <el-form-item label="分享链接标语:" prop="">
          <el-input v-model="form.shareLinkBanner" placeholder="请输入分享链接标语" />
        </el-form-item>
        <el-form-item label="分享链接图片:" prop="">
          <div class="pictures_box_left">
            <img-big-upload
              :imageShow.sync="form.shareLinkPicture"
              :addTitleShow="false"
              @removeImg="removeImgDians"
              @uploadOneImgShow="uploadOneImgShowDians"
            />
          </div>
          <div class="img_tips">
            <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB) </span>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="button_box">
      <div class="save button" @click="clickSave">保存</div>
      <div class="cancel button" @click="clickCancel">取消</div>
    </div>
  </div>
</template>
<script>
import {
  getVideo,
  selectById,
  addActivity,
  editActivity,
  selectCompanyByName,
  selectChamberCommerceByName
} from '@/api/activecenter'
import { selectIdAndArticleName } from '@/api/articlecenter'
import Editorbar from '@/components/Editorbar.vue'
import CitySelect from '@/components/CitySelect.vue'
import uploadVideo from './uploadVideo.vue'
const defaultTicketItem = Object.freeze({
  commonUserDiscount: 0, // 普通用户折扣/优惠价
  commonUserExpenseType: '1', // 普通用户费用类型（1、付费；2、免费）
  commonUserFavorableType: '1', // 普通用户优惠类型（1、折扣；2、优惠价）
  id: null, //
  isOne: '1', // 是否单人单张（1、是；2、否）
  memberDiscount: 0, // 会员折扣/优惠价
  memberExpenseType: '1', // 会员费用类型（1、付费；2、免费）
  memberFavorableType: '1', // 会员优惠类型（1、折扣；2、优惠价）
  remark: '', // 备注
  secretaryGeneralDiscount: 0, // 秘书长折扣/优惠价
  secretaryGeneralExpenseType: '1', // 秘书长费用类型（1、付费；2、免费）
  secretaryGeneralFavorableType: '1', // 秘书长优惠类型（1、折扣；2、优惠价）
  ticketName: '普通票', // 票名
  ticketNumber: 0, // 票数
  ticketRates: 0 // 票价
})
export default {
  components: { Editorbar, CitySelect, uploadVideo },
  props: {
    activeId: {}
  },
  data() {
    const coverCheck = (rule, value, callback) => {
      // console.log(rule)
      value.forEach((el) => {
        if (!el.activityName) {
          callback(new Error('请输入封面标题'))
        } else if (!el.surfaceDrawing) {
          callback(new Error('请上传封面图'))
        } else {
          callback()
        }
      })
    }
    const userTypesCheck = (rule, value, callback) => {
      // console.log(rule)
      if (!value.length) {
        callback(new Error('请选择参与用户'))
      } else {
        callback()
      }
    }

    return {
      relateArticleList: [], // 关联快讯列表
      chamberCommerceList: [], // 关联商会列表
      companyFullNameList: [], // 关联企业列表
      videoUrl: '', // 视频链接
      value: '',
      operatingList: [], //省
      operatingLists: [], //市
      pickerDateList: [],
      // 日期快捷按钮
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      //  活动票种item
      ticketItem: { ...defaultTicketItem },
      // 传参
      form: {
        // 封面信息
        coverInformationVo: [
          {
            activityName: '', // 活动名称
            surfaceDrawing: '' // 封面图
          }
        ],
        // 关联商会列表
        chamberCommerceVo: [
          {
            chamberCommerceName: '',
            companyFullName: '',
            id: null
          }
        ],
        // 关联企业列表
        firmVo: [
          {
            chamberCommerceName: '',
            companyFullName: '',
            id: null
          }
        ],
        wxImage: '', // 微信二维码图片
        phaseNumber: 15, // 提前预热天数
        activityCity: '', // 活动城市
        activityDescription: '', // 活动描述
        activityDetailedAddress: '', // 活动详情地址
        activityDistrict: '', //  活动地区
        activityEndTime: '', // 活动结束时间
        activityName: '', //  活动名称
        activityNumber: 0, // 活动名额
        activityProvince: '', // 活动省份
        activityStartTime: '', // 活动开始时间
        activityTickets: [
          // 活动票种
          {
            commonUserDiscount: 0, // 普通用户折扣/优惠价
            commonUserExpenseType: '1', // 普通用户费用类型（1、付费；2、免费）
            commonUserFavorableType: '1', // 普通用户优惠类型（1、折扣；2、优惠价）
            id: null, //
            isOne: '1', // 是否单人单张（1、是；2、否）
            memberDiscount: 0, // 会员折扣/优惠价
            memberExpenseType: '1', // 会员费用类型（1、付费；2、免费）
            memberFavorableType: '1', // 会员优惠类型（1、折扣；2、优惠价）
            remark: '', // 备注
            secretaryGeneralDiscount: 0, // 秘书长折扣/优惠价
            secretaryGeneralExpenseType: '1', // 秘书长费用类型（1、付费；2、免费）
            secretaryGeneralFavorableType: '1', // 秘书长优惠类型（1、折扣；2、优惠价）
            ticketName: '普通票', // 票名
            ticketNumber: 0, // 票数
            ticketRates: 0 // 票价
          }
        ],
        detailsPageVideoTwo: '', // 详情页视频 (展示)
        homeVideoTwo: '', // 首页视频  (展示)
        detailsPageVideo: '', // 详情页视频(id)
        homeVideo: '', // 首页视频(id)
        activityType: '1', // 活动类型（1、实战课程；2、工赋学堂）
        activityPhase: '1', // 活动阶段（1、预热中；2、规划中）
        articleId: null, // 关联快讯id
        articleName: '', // 关联快讯名称
        caseSubject: '', // 案例主题
        createId: null, //
        createTime: '', //
        id: null, //
        participationMode: '1', // 参与方式（1、线上；2、线下）
        restaurantDetailedAddress: '', // 饭店详情地址
        shareLinkBanner: '', // 分享链接标语
        shareLinkPicture: '', // 分享链接图片
        surfaceDrawing: '', // 封面图
        ticketTypeId: '', // 活动票种id
        updateId: null, //
        updateTime: '', //
        userType: '', // 用户参与类型（0：普通用户；3：申请秘书长中；1：秘书长用户；2：秘书长会员用户；）
        userTypes: [] // 用户参与类型集合
      },
      imageUrlList: [],
      isClear: false,
      updatePwdRules: {
        coverInformationVo: [{ required: true, validator: coverCheck, trigger: 'blur' }],
        caseSubject: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        activityType: [{ required: true, message: '请选择活动类型', trigger: 'blur' }],
        // activityEndTime: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        // sponsor: [{ required: true, message: '请输入主办方', trigger: 'blur' }],
        informationBrief: [{ required: true, message: '请输入一句话简介', trigger: 'blur' }],
        issueName: [{ required: true, message: '请输入发布机构/人' }],
        informationType: [{ required: true, message: '请输入活动类型', trigger: 'change' }],
        notes: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        activityDescription: [{ required: true, message: '请输入活动描述', trigger: 'change' }],
        activityImg: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        userTypes: [{ required: true, validator: userTypesCheck, trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.incity()
    if (this.activeId) {
      this.getSelectById()
    }
  },

  mounted() {},
  //方法集合
  methods: {
    // 获取视频url和视频封面图
    async getVideo() {
      const { data: res } = await getVideo({ videoId: this.form.homeVideo })
      if (res.resultCode === 200) {
        this.videoUrl = res.data.video
        this.form.coverImage = res.data.image
      }
    },
    //返回省
    searchProvince(val) {
      this.form.activityProvince = val
    },
    //返回城市
    searchCity(val) {
      this.form.activityCity = val
    },
    //返回区/县
    searchDistrict(val) {
      this.form.activityDistrict = val
    },
    // 选中日期
    checkParamsData(val) {
      this.form.activityStartTime = val[0]
      this.form.activityEndTime = val[1]
    },
    // 修改活动状态
    setActivityPhase(val) {
      if (val === '1') {
        // 预热中
        // this.pickerDateList = ['', '']
        const item = { ...defaultTicketItem }
        this.form.activityTickets = []
        this.form.activityTickets.push(item)
        this.form.activityProjectTime = ''
        this.form.phaseNumber = 15
      } else {
        // 规划中
        this.form.activityStartTime = ''
        this.form.activityEndTime = ''
        this.form.phaseNumber = 0
        this.form.activityTickets = []
      }
    },
    // 修改优惠方式or付费类型
    discountChanged(item, val) {
      // val代表用户类型：1，普通用户；2，秘书长；3，会员用户
      // commonUserDiscount secretaryGeneralDiscount memberDiscount
      if (val === 1) {
        item.commonUserDiscount = 0
      } else if (val === 2) {
        item.secretaryGeneralDiscount = 0
      } else {
        item.memberDiscount = 0
      }
    },
    // 富文本编辑器的内容
    inputVla(val) {
      // console.log(val)
    },
    // 获取活动详情
    async getSelectById() {
      const { data: res } = await selectById({ id: this.activeId })
      if (res.resultCode == 200) {
        this.form = res.data
        if (this.form.homeVideo) {
          // 如果视频id不为空 调接口获取视频url和封面图
          this.getVideo()
        }
        // 封面图非空处理
        if (!this.form.surfaceDrawing) {
          this.form.surfaceDrawing = ''
        }
        // 参与用户类型
        if (!this.form.userTypes) {
          this.form.userTypes = []
        }
        // 小程序分享图片非空处理
        if (!this.form.shareLinkPicture) {
          this.form.shareLinkPicture = ''
        }
        // 微信二维码图片非空处理
        if (!this.form.wxImage) {
          this.form.wxImage = ''
        }
        // 封面信息非空处理
        if (!this.form.coverInformationVo) {
          this.form.coverInformationVo = [
            {
              activityName: '', // 活动名称
              surfaceDrawing: '' // 封面图
            }
          ]
        } else if (!this.form.coverInformationVo.length) {
          this.form.coverInformationVo = [
            {
              activityName: '', // 活动名称
              surfaceDrawing: '' // 封面图
            }
          ]
        }
        // 关联商会列表非空处理
        if (!this.form.chamberCommerceVo) {
          this.form.chamberCommerceVo = [
            {
              chamberCommerceName: '',
              companyFullName: '',
              id: null
            }
          ]
        } else if (!this.form.chamberCommerceVo.length) {
          this.form.chamberCommerceVo = [
            {
              chamberCommerceName: '',
              companyFullName: '',
              id: null
            }
          ]
        }
        // 关联企业列表非空处理
        if (!this.form.firmVo) {
          this.form.firmVo = [
            {
              chamberCommerceName: '',
              companyFullName: '',
              id: null
            }
          ]
        } else if (!this.form.firmVo.length) {
          this.form.firmVo = [
            {
              chamberCommerceName: '',
              companyFullName: '',
              id: null
            }
          ]
        }
        // 时间段回显处理
        if (this.form.activityPhase === '1') {
          if (this.form.activityStartTime && this.form.activityEndTime) {
            this.pickerDateList.push(this.form.activityStartTime)
            this.pickerDateList.push(this.form.activityEndTime)
          } else {
            this.pickerDateList = ['', '']
          }
        }
      } else {
        this.$message.error(res.message)
      }
    },
    //添加封面信息 item
    addCoverItemBtn() {
      this.form.coverInformationVo.push({
        activityName: '', // 活动名称
        surfaceDrawing: '' // 封面图
      })
    },
    //删除封面信息 item
    delCoverItemBtn(index) {
      this.form.coverInformationVo.splice(index, 1)
    },
    //新增
    addItem() {
      const item = { ...defaultTicketItem }
      this.form.activityTickets.push(item)
    },
    //删除
    delItem(index) {
      this.form.activityTickets.splice(index, 1)
    },
    //删除活动封面图
    removeImgDianspeci(val) {
      this.form.activityImg = ''
    },
    //添加活动封面图
    uploadOneImgShowDianspeci(val) {
      this.form.activityImg = val
    },

    //视频上传
    uploadOneImgvideo(val) {
      this.form.homeVideo = val
      this.getVideo()
    },
    // 删除视频
    removeImgvideo() {
      this.form.homeVideo = ''
    },
    //关联快讯
    //快讯名称输入框变化
    relateArticleNameChanged() {
      this.relateArticleList = []
    },
    //选择快讯名称
    async relateArticleNameSelect() {
      const query = {
        type: '2', // 1是快讯，2是活动
        id: this.id ? this.id : 0, // 活动id
        articleName: this.form.articleName
      }
      const { data: res } = await selectIdAndArticleName(query)
      if (res.resultCode === 200) {
        this.form.articleId = res.data[0].id
        this.relateArticleList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询快讯名称
    async relateArticleNameQuerySearch(str, cb) {
      if (str != '') {
        const query = {
          type: '2',
          id: this.id ? this.id : 0,
          articleName: str
        }
        const { data: res } = await selectIdAndArticleName(query)
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.relateArticleList.push({
              value: item.articleName,
              id: item.id
            })
          })
          cb(this.relateArticleList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    // 关联商会
    //添加关联商会 item
    addRelateProjectBtn() {
      this.form.chamberCommerceVo.push({
        chamberCommerceName: '',
        companyFullName: '',
        id: null
      })
    },
    //删除关联商会 item
    delRelateProjectBtn(index) {
      this.form.chamberCommerceVo.splice(index, 1)
    },
    //商会名称输入框变化
    relateChamberCommerceNameChanged() {
      this.chamberCommerceList = []
    },
    //选择商会名称
    async relateChamberCommerceNameSelect(val, item) {
      // console.log(val)
      const { data: res } = await selectChamberCommerceByName({
        chamberCommerceName: val.value
      })
      if (res.resultCode === 200) {
        const obj = res.data[0]
        item.id = obj.id
        this.chamberCommerceList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询商会名称
    async relateChamberCommerceNameQuerySearch(str, cb) {
      if (str != '') {
        const { data: res } = await selectChamberCommerceByName({ chamberCommerceName: str })
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.chamberCommerceList.push({
              value: item.chamberCommerceName,
              id: item.id
            })
          })
          cb(this.chamberCommerceList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    // 关联企业
    //添加关联企业 item
    addRelateCompanyFullNameBtn() {
      this.form.firmVo.push({
        chamberCommerceName: '',
        companyFullName: '',
        id: null
      })
    },
    //删除关联企业 item
    delRelateCompanyFullNameBtn(index) {
      this.form.firmVo.splice(index, 1)
    },
    //企业名称输入框变化
    relateCompanyFullNameChanged() {
      this.companyFullNameList = []
    },
    //选择企业名称
    async relateCompanyFullNameSelect(val, item) {
      const { data: res } = await selectCompanyByName({ keywords: val.value })
      if (res.resultCode === 200) {
        const obj = res.data[0]
        item.id = obj.id
        this.companyFullNameList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询企业名称
    async relateCompanyFullNameQuerySearch(str, cb) {
      if (str != '') {
        const { data: res } = await selectCompanyByName({ keywords: str })
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.companyFullNameList.push({
              value: item.name,
              id: item.id
            })
          })
          cb(this.companyFullNameList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    //获取微信二维码图片
    uploadOneWXImgShowDians(val) {
      this.form.wxImage = val
    },
    // 删除微信二维码图片
    removeWXImgDians(val) {
      this.form.wxImage = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.shareLinkPicture = val
    },
    // 删除小程序分享封面图
    removeImgDians(val) {
      this.form.shareLinkPicture = ''
    },
    // 校验优惠价和折扣是否合理
    checkPrice() {
      let bol = true
      if (this.form.activityTickets.length > 0) {
        // 活动不是规划中才需要校验
        this.form.activityTickets.forEach((el) => {
          const max = el.ticketRates // 票种价格为最大值
          if (el.commonUserFavorableType === '2') {
            // 普通用户为优惠价时
            if (el.commonUserDiscount < 0 || el.commonUserDiscount > max) {
              bol = false
            }
          }
          if (el.memberFavorableType === '2') {
            // 会员用户为优惠价时
            if (el.memberDiscount < 0 || el.memberDiscount > max) {
              bol = false
            }
          }
          if (el.secretaryGeneralFavorableType === '2') {
            //秘书长用户为优惠价时
            if (el.secretaryGeneralDiscount < 0 || el.secretaryGeneralDiscount > max) {
              bol = false
            }
          }
        })
      }
      return bol
    },
    // 保存
    clickSave() {
      // 校验优惠价小于原票价且不为负数
      const bol = this.checkPrice()
      if (bol) {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let num = 0
            if (this.form.activityTickets.length > 0) {
              this.form.activityTickets.forEach((el) => {
                num += el.ticketNumber
              })
            }
            if (this.form.activityNumber < num) {
              this.$message.warning('设置的总票数不能大于活动名额~')
            } else {
              const loading = this.$loading({
                lock: true,
                text: '保存中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              })
              const fn = !this.form.id ? addActivity : editActivity
              const txt = !this.form.id ? '新增' : '编辑'
              const { data: res } = await fn(this.form)
              if (res.resultCode === 200) {
                loading.close()
                this.$message.success(txt + '成功!')
                this.$router.go(-1)
              } else {
                loading.close()
                this.$message.error(txt + '失败!')
              }
            }
          }
        })
      } else {
        this.$message.warning('请为各票种设置合理的优惠价~')
      }
    },
    // 取消
    clickCancel() {
      this.$router.back()
      // console.log('取消')
    },

    //多个图片上传显示
    uploadOneImg(val, item) {
      item.surfaceDrawing = val
    },
    // 移除
    removeImg(val, item) {
      console.log(val)
      item.surfaceDrawing = ''
    }
  }
}
</script>
<style scoped lang="less">
.formBox {
  padding-top: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .online_color {
    margin-left: 10px;
    color: #999999;
  }
  .icon_btn {
    cursor: pointer;
    margin: 0 10px;
    i {
      font-size: 18px;
    }
  }
  .add {
    color: #4e93fb;
  }
  .del {
    color: #ff7575;
  }
  .el-radio-group {
    line-height: 40px;
  }
  .mini_input_row {
    display: flex;
    width: 200px;
    line-height: 40px;
    span {
      margin-left: 8px;
    }
  }
  .img_tips {
    font-size: 12px;
    color: #bebebe;
  }
  .h4_share {
    padding: 50px 50px 0px 30px;
    color: #146aff;
  }
  .tiket_flex {
    display: flex;
    margin-bottom: 10px;
  }
  .ticket_Type {
    width: 600px;
    // height: 980px;
    padding: 20px 0px;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    padding-left: 80px;
    min-height: 600px;
    .add_information {
      margin-left: 40px;
      width: 433.54px;
      min-height: 100px;
      border: 1px dashed #c4c4c4;
      border-radius: 2px;
      margin-bottom: 15px;
      .online_conferences {
        margin-top: 16px;
        width: 447.76px;
        height: 305.17px;
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        margin-left: 120px;
        margin-bottom: 10px;
      }
    }
  }

  .inputBox {
    height: 40px;
    display: flex;
  }
  .general_inputs {
    width: 585px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
    .input_flex {
      display: flex;
      justify-content: space-between;
      padding: 7px 0px;
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }

  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .labenBox {
    width: 120px;
    display: flex;

    margin-right: 12px;

    .label {
      color: #333333;
      font-size: 14px;
      margin-right: 2px;
    }
    .span {
      color: #f05252;
    }
  }

  .logo_box {
    display: flex;
    .label_box {
      display: flex;
      margin-right: 12px;
      width: 120px;
      justify-content: flex-end;
      .logo_label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  }

  .picker {
    width: 240px !important;
  }

  /deep/.el-radio__input {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__label {
    font-size: 14px;
    color: #333333;
  }

  .button_box {
    width: 100%;
    // height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    padding-bottom: 55px;
    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .editBarBox {
    width: 100%;
    display: flex;

    .editbar_text {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      .text_a {
        margin-right: 2px;
      }
      .span {
        margin-right: 12px;
        color: #f05252;
      }
    }

    .editBar {
      // width: 686px;
      // width: 100%;
      width: 598.31px;
      height: 437px;
    }
  }
}

.mr-b-10 {
  margin-bottom: 10px;
}
.cover_list {
  display: flex;
  flex-flow: column;
  .cover_item {
    display: flex;
    .item_content {
      width: fit-content;
      padding: 10px;
      border: 1px dashed #c4c4c4;
      margin-bottom: 10px;
      .item_title {
        font-size: 12px;
        line-height: 20px;
        color: #333;
      }
      .cover {
        display: flex;
        flex-direction: column;
      }
    }
    .row {
      display: flex;
    }
  }
}
.thumbnail {
  display: flex;

  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}
::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 390px;
  max-height: 390px;
}
::v-deep .w-e-text-container .w-e-text {
  max-height: 390px;
}
::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}

::v-deep .original .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
}
</style>
